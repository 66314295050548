import {utils} from "./utils";

/**
 * Marquee
 */
document.querySelectorAll('.marquee-inner').forEach((el, i) => {

	let translateWidth;
	const marqueeWidth      = el.parentNode.scrollWidth;
	const marqueeInnerWidth = el.scrollWidth;
	const speedCoefficient  = 30; // higher values for slower speed

	if (marqueeInnerWidth < marqueeWidth)
	{
		translateWidth = marqueeInnerWidth
	}
	else
	{
		translateWidth = marqueeWidth
	}

	// adapt marquee speed based on content length
	el.style.animationDuration = translateWidth / window.visualViewport.width * speedCoefficient + 's';
	//el.style.animationDuration = '10s';

	el.parentNode.style.setProperty('--translate-amount', '-' + (translateWidth + 80) * 100 / window.visualViewport.width + '%');

	utils.duplicateChildNodes(el);

	if (el.parentNode.classList.contains('marquee--inverse'))
	{
		el.style.setProperty('direction', 'rtl');
	}
});