import {goToByScroll} from './go-to-by-scroll';
import {utils} from './utils';
import {bindLinkToItem} from './bind-link-to-item';
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';


let heroLogoHome = document.querySelector('.home .hero-logo');

if (heroLogoHome !== null)
{
	let start = '+200px';

	if (matchMedia('(max-width: 767.98px)').matches)
	{
		start = 'top';
	}

	gsap.to(heroLogoHome, {
		xPercent:      -50,
		ease:          'linear',
		startAt:       {
			xPercent: 0
		},
		scrollTrigger: {
			trigger:    '#content',
			endTrigger: '.hero-logo',
			start:      start + ' top',
			end:        'bottom top',
			scrub:      1,
			//markers:    true
		},
	});
}

let heroLogoSlooow = document.querySelector('.page-template-template-slooow .hero-logo');

if (heroLogoSlooow !== null)
{
	gsap.to(heroLogoSlooow, {
		xPercent:      -50,
		ease:          'linear',
		startAt:       {
			xPercent: 0
		},
		scrollTrigger: {
			trigger:    '#content',
			endTrigger: '.hero-logo',
			start:      'top top',
			end:        'bottom top',
			scrub:      1,
			//markers:    true
		},
	});
}

let team = document.querySelector('.team');

if (team !== null)
{
	const distance = team.scrollWidth - document.body.clientWidth - 300;

	gsap.to(team, {
		x:             distance * -1,
		ease:          'linear',
		startAt:       {
			x: '-230px'
		},
		scrollTrigger: {
			trigger: '.team',
			start:   'top top',
			end:     '+1000px',
			scrub:   1,
			pin:     true,
			//markers:    true
		},
	});
}

/**
 * Posts masonry
 */
const itemsGrid = document.querySelector('.posts-masonry');

if (itemsGrid !== null)
{
	let isotopeItemsGrid;

	const breakpointMobileChecker = function () {

		if (breakpointMobile.matches)
		{
			// if isotope is initialized, destroy it
			if (Isotope.data(itemsGrid) !== undefined)
			{
				isotopeItemsGrid.destroy();
			}
		}
		else if (itemsGrid.querySelectorAll('.caso-studio').length > 1)
		{
			isotopeItemsGrid = new Isotope(itemsGrid, {
				itemSelector:    '.caso-studio-card',
				layoutMode:      'masonry',
				percentPosition: true,
				masonry:         {
					columnWidth:     '.caso-studio-card--regular',
					gutter:          '.gutter-sizer',
					horizontalOrder: true
				}
			});

			imagesLoaded(itemsGrid).on('progress', function () {
				// layout Isotope after each image loads
				isotopeItemsGrid.layout();
			});
		}
	};

	const breakpointMobile = window.matchMedia('(max-width: 767.98px)');

	// keep an eye on viewport size changes
	breakpointMobile.addEventListener('change', breakpointMobileChecker);

	// kickstart
	breakpointMobileChecker();
}


/**
 * Load more posts via ajax on click
 */
let loadMorePosts = function (e) {
	e.preventDefault();

	const data = new FormData();
	data.append('action', 'load_more_posts');
	data.append('nonce', ajax_vars.nonce);
	data.append('query', ajax_vars.posts);
	data.append('page', ajax_vars.current_page);

	fetch(ajax_vars.ajax_url, {
		method:      'POST',
		credentials: 'same-origin',
		body:        data
	})
		.then((response) => response.text())
		.then((data) => {
			if (data)
			{
				ajax_vars.current_page++;

				const postsMasonry     = document.querySelector('.posts-masonry');
				const isotopeItemsGrid = Isotope.data(postsMasonry);

				const parser     = new DOMParser();
				const doc        = parser.parseFromString(data, 'text/html');
				const nodes      = doc.body.childNodes;
				const nodesArray = Array.from(nodes);

				for (let i = 0; i < nodesArray.length; i++)
				{
					postsMasonry.appendChild(nodesArray[i]);
				}

				isotopeItemsGrid.appended(nodesArray);
				isotopeItemsGrid.layout();

				bindLinkToItem('.link-all-item');

				if (ajax_vars.current_page === ajax_vars.max_page)
				{
					loadMoreButton.remove() // if last page, remove the button
				}
			}
			else
			{
				loadMoreButton.remove() // if no data, remove the button as well
			}
		})
		.catch((error) => {
			console.error(error);
		});
};

const loadMoreButton = document.querySelector('.load-more-posts');

if (loadMoreButton !== null)
{
	loadMoreButton.removeEventListener('click', loadMorePosts);
	loadMoreButton.addEventListener('click', loadMorePosts);
}


const casiStudioFiltersToggles = document.querySelectorAll('.toggle-casi-studio-filters');

casiStudioFiltersToggles.forEach(el => {
	el.addEventListener('click', function (e) {
		document.body.classList.toggle('casi-studio-filters-open');
	});
});

/**
 * Tabs
 */
const tabToggles = document.querySelectorAll('.tabs__header span[data-tab]');

tabToggles.forEach(el => {
	el.addEventListener('click', function (e) {
		const tabName = el.getAttribute('data-tab');

		this.classList.add('active');

		let otherToggles = utils.getSiblings(this);

		otherToggles.forEach(el => {
			el.classList.remove('active');
		});

		const activeTab = document.querySelector('.tabs__tabs [data-tab="' + tabName + '"]');
		activeTab.classList.add('active')

		//goToByScroll(document.querySelector('.tabs'));

		let otherTabs = utils.getSiblings(activeTab);

		otherTabs.forEach(el => {
			el.classList.remove('active');
		});
	});
});


const playBtns = document.querySelectorAll('.play');

if (playBtns.length > 0)
{
	playBtns.forEach(function (el) {
		const video = el.previousElementSibling;

		el.addEventListener('click', function () {
			video.play();
			video.setAttribute('controls', '');
			el.classList.remove('active');
		});

		video.addEventListener('pause', function () {
			video.removeAttribute('controls');
			el.classList.add('active');
		}, false);

		video.addEventListener('ended', function () {
			video.removeAttribute('controls');
			el.classList.add('active');
		}, false);
	});
}


/**
 * Change sticky image on scroll
 */
const sezioneScrollnavSections = gsap.utils.toArray('.sezione-scrollnav__section');
const sezioneScrollnavNavbar   = document.querySelector('.sezione-scrollnav__navbar');

sezioneScrollnavSections.forEach((item) => {

	let firstItem = sezioneScrollnavNavbar.querySelector('[data-id="01"]');

	firstItem.classList.add('active');

	ScrollTrigger.create({
		trigger:  item,
		start:    () => 'top center',
		end:      () => 'bottom center',
		onToggle: ({progress, direction, isActive}) => {

			if (isActive)
			{
				const oldActiveItem = sezioneScrollnavNavbar.querySelector('.active');

				if (oldActiveItem !== null)
				{
					oldActiveItem.classList.remove('active')
				}

				const index = item.getAttribute('data-id');

				let newActiveItem = sezioneScrollnavNavbar.querySelector('[data-id="' + index + '"]');

				newActiveItem.classList.add('active');
			}
		},
		//markers:     true
	});
});

if (sezioneScrollnavNavbar !== null)
{
	sezioneScrollnavNavbar.querySelectorAll('[data-id]').forEach((item) => {
		item.addEventListener('click', function () {
			const index = item.getAttribute('data-id');
			let section = document.querySelector('.sezione-scrollnav__section[data-id="' + index + '"]');
			goToByScroll(section, -168);
		}, false);
	});
}


/**
 * Cross fade loghi
 * @type {NodeListOf<Element>}
 */
const gridLoghi = document.querySelectorAll('.grid-loghi');

gridLoghi.forEach(marchiModule => {
	setInterval(function () {
		let loghiInterventi = marchiModule.querySelectorAll('.grid-loghi__logo:not(.visible):not(.blocked)');
		let newVisibleLogo  = loghiInterventi[Math.floor(Math.random() * (loghiInterventi.length))];

		newVisibleLogo.classList.add('visible');

		const newVisibleLogoSiblings = utils.getSiblings(newVisibleLogo);

		newVisibleLogoSiblings.forEach(el => {
			el.classList.add('blocked');
			el.classList.remove('visible');
		});

		setTimeout(function () {
			newVisibleLogoSiblings.forEach(el => {
				el.classList.remove('blocked');
			});
		}, 2000);

	}, 1000);
});



/**
 * Force ALL ScrollTrigger instances to re-calculate its start and end values after each image is loaded
 */
let images = document.querySelectorAll('img');

Promise.all(Array.from(images).map(img => {

	if (img.complete)
	{
		return Promise.resolve(img.naturalHeight !== 0);
	}

	return new Promise(resolve => {

		img.addEventListener('load', () => {

			// refresh here
			ScrollTrigger.refresh();

			resolve(true);
		});

		img.addEventListener('error', () => resolve(false));

	});

})).then(results => {

	if (results.every(res => res))
	{
		//console.log('all images loaded successfully');
	}
	else
	{
		//console.log('some images failed to load, all finished loading');
	}

});



/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {


});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {

	//ScrollTrigger.getAll().forEach(t => t.refresh());

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/