import {utils} from "./utils";

let gallerySlideshowSwiper = new Swiper('.gallery-display-as-slideshow .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  16,
	speed:         500,
	autoHeight:    true,
	pagination:    {
		el:        ".swiper-pagination",
		clickable: true
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});


let logosSlideshowSwiper = new Swiper('.logos-slideshow', {
	slidesPerView:      'auto',
	spaceBetween:       16,
	slidesOffsetBefore: 20,
	slidesOffsetAfter:  20,
	speed:              1000,
	loop:               true,
	autoplay:           {
		delay:           3000,
		stopOnLastSlide: false
	},
	breakpoints:        {
		768: {
			spaceBetween:       24,
			slidesOffsetBefore: 48,
			slidesOffsetAfter:  48
		}
	}
});


let casiStudioSwiper = new Swiper('.casi-studio-swiper .swiper', {
	slidesPerView:  'auto',
	spaceBetween:   24,
	speed:         1600,
	autoHeight:     true,
	centeredSlides: true,
	slideToClickedSlide: true,
	autoplay:           {
		delay:           4000,
		stopOnLastSlide: false
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination:     {
		el:        ".swiper-pagination",
		clickable: true
	},
	breakpoints:    {
		992: {
			spaceBetween: 48
		}
	}
});

let moduleImmaginiSwiper = new Swiper('.module-immagini .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  16,
	speed:         600,
	autoHeight:    true,
	pagination:    {
		el:             ".swiper-pagination",
		type:           "fraction",
		renderFraction: function (currentClass, totalClass) {
			return '<span class="' + currentClass + '"></span>' +
				' di ' +
				'<span class="' + totalClass + '"></span>';
		}
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on:         {
		afterInit: function (swiper) {
			if (swiper.slides.length < 2)
			{
				swiper.el.classList.add('swiper--no-pagination');
			}
		},
	},
});

/*let teamSwiper = new Swiper('.swiper-team', {
	slidesPerView: 'auto',
	spaceBetween:  8,
	autoHeight:    false,
	freeMode:      true,
	speed:         1000,
	grabCursor:    true,
	breakpoints:    {
		768: {
			spaceBetween: 40
		}
	}
});*/

let momentsSwiper = new Swiper('.swiper-moments', {
	slidesPerView: 'auto',
	spaceBetween:  48,
	autoHeight:    true,
	freeMode:      true,
	speed:         1000,
	grabCursor:    true
});

let moduleFeaturedCasiStudioSwiper = new Swiper('.module-featured-casi-studio .swiper', {
	slidesPerView: 1,
	spaceBetween:  16,
	slidesOffsetBefore: 0,
	slidesOffsetAfter:  0,
	speed:         600,
	autoHeight:    false,
	pagination:    {
		el:             ".swiper-pagination",
		type:           "fraction",
		renderFraction: function (currentClass, totalClass) {
			return '<span class="' + currentClass + '"></span>' +
				' di ' +
				'<span class="' + totalClass + '"></span>';
		}
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,

		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on:         {
		afterInit: function (swiper) {
			if (swiper.slides.length < 2)
			{
				swiper.el.classList.add('swiper--no-pagination');
			}
		},
	},
	breakpoints:        {
		992: {
			slidesPerView: 'auto',
			slidesOffsetBefore: 32,
			slidesOffsetAfter:  32
		}
	}
});

let testimonialsSwiper = new Swiper('.testimonials .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  16,
	speed:         600,
	autoHeight:    false,
	pagination:    {
		el:             ".swiper-pagination",
		type:           "fraction",
		renderFraction: function (currentClass, totalClass) {
			return '<span class="' + currentClass + '"></span>' +
				' di ' +
				'<span class="' + totalClass + '"></span>';
		}
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on:         {
		afterInit: function (swiper) {
			if (swiper.slides.length < 2)
			{
				swiper.el.classList.add('swiper--no-pagination');
			}
		},
	},
});

let progettiSwiper = new Swiper('.progetti .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  16,
	speed:         600,
	autoHeight:    false,
	pagination:    {
		el:             ".swiper-pagination",
		type:           "fraction",
		renderFraction: function (currentClass, totalClass) {
			return '<span class="' + currentClass + '"></span>' +
				' di ' +
				'<span class="' + totalClass + '"></span>';
		}
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	on:         {
		afterInit: function (swiper) {
			if (swiper.slides.length < 2)
			{
				swiper.el.classList.add('swiper--no-pagination');
			}
		},
	},
});